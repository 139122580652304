.dialog-wrapper {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

.dialog-backdrop {
  position: fixed;
  height: 100vh;
  width: 100vw;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 100;
  background-color: rgba($color: #000000, $alpha: 0.5);
}

.dialog-box {
  overflow: hidden;
  position: fixed;
  margin: auto;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  max-height: 22rem;
  max-width: 25rem;
  overflow-y: auto;
  background-color: white;
  box-shadow: 0 0 10px rgba(white, 0.7);
  z-index: 10000;
  border-radius: 5px;
  font-size: 14px !important;
}

.dialog-message {
  text-align: justify;
}

.dialog-input {
  width: 23rem;
  padding: 5px 7px;
  border-radius: 5px;
  border: 1px solid #ddd;
}

.dialog-input:focus {
  outline: 0;
}

.dialog-header {
  margin: 0.5rem 0 1rem;
}

.delete-dialoge-warning {
  $background: rgba(255, 0, 0, 0.2);
  $border-color: rgba(255, 0, 0, 0.4);

  padding: 20px 0;
  border-top: 1px solid $border-color;
  border-bottom: 1px solid $border-color;
  background-image: linear-gradient($background, $background);
}

.btn-delete-dialog {
  border: 1px solid #ccc;
  border-radius: 4px;
}

.btn-delete {
  background-color: rgb(220, 53, 69);
  color: white;
  cursor: pointer;
}

.btn-delete:hover {
  background-color: rgb(190, 53, 69);
}

.btn-cancel {
  background-color: white;
}

.btn-cancel:hover {
  background-color: #ededed;
}

.btn-delete:disabled {
  opacity: 0.5;
  cursor: not-allowed;
}
