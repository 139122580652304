.global-filter {
  border: 1px solid #e3e3e3;
  border-radius: 5px;
  padding: 5px 10px;
  font-size: 12px;
}

.global-filter:focus {
  outline: 0;
  border: 1px solid $primary;
}
