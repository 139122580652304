.data-detail-on-popup-container {
  border-radius: 3px;
  background-color: rgba($color: #fff, $alpha: $popup-opacity);
  width: 15rem;

  .img-container {
    border-top-left-radius: inherit;
    border-top-right-radius: inherit;
    height: 100px;
    background-repeat: no-repeat;
    text-align: center;

    img {
      height: 100px;
      border-radius: inherit;
      object-fit: contain;
      // object-fit: cover;
    }
  }

  .data-detail {
    padding: 0.6rem;

    .data-detail-item {
      padding: 0.15rem 0;
    }
  }
}
