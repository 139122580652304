.data-on-grid-container {
  --gap: 20px;
  --num-cols: 5;
  --row-height: 270px;

  height: 93%;
  box-sizing: border-box;
  padding: 2rem 2rem;

  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(240px, 270px));
  grid-auto-rows: var(--row-height);
  gap: var(--gap);
  // justify-content: center;
  // margin-bottom: 2rem;
}

.data-on-grid-item {
  width: 100%;
  height: 100%;
  border: 1px solid #f3f3f3;
  border-radius: 3px;
  color: #585858;
  padding: 0 !important;
  position: relative;
}

.data-on-grid-item img {
  margin: 0 !important;
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-top-left-radius: inherit;
  border-top-right-radius: inherit;
}

.data-on-grid-item span {
  height: 75%;
  border-radius: inherit;
}

.data-on-grid-item-detail {
  padding: 10px;

  h6 {
    font-size: 13px;
    font-weight: bold;
    font-family: "Inter" !important;
    margin: 0;
    padding: 0;
    margin-bottom: 0.22rem;
  }

  p {
    font-size: 10px;
  }
}

.grid-layout-popup {
  position: absolute;
  top: 30px;
  right: 2px;
  z-index: 1;
  border: 1px solid #f3f3f3;
  border-radius: 3px;
  background-color: white;
}

.grid-layout-popup-item {
  font-size: 12px;
  width: 100%;
  padding: 0.5rem 0.7rem;
  border: 0;
  outline: 0;
  cursor: pointer;
}

.grid-layout-popup-item:first-of-type {
  border-top-left-radius: inherit;
  border-top-right-radius: inherit;
}

.grid-layout-popup-item:last-of-type {
  border-bottom-left-radius: inherit;
  border-bottom-right-radius: inherit;
}

.grid-layout-popup-item:hover {
  background-color: #ddd;
}

@media screen and (max-width: 1270px) {
  .data-on-grid-container {
    --num-cols: 4;
  }
}

@media screen and (max-width: 1085px) {
  .data-on-grid-container {
    --num-cols: 3;
  }
}

@media screen and (max-width: 905px) {
  .data-on-grid-container {
    --num-cols: 3;
  }
}

@media screen and (max-width: 767px) {
  .data-on-grid-container {
    --num-cols: 2;
  }
}

@media screen and (max-width: 510px) {
  .data-on-grid-container {
    --num-cols: 1;
  }
}
