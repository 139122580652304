$primary: #4fc9da;
$secondary: #333;
$input-background: #e1e1e1;
$warning: #dc3545;

$popup-opacity: 1;

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "Rubik", "Inter", -apple-system, BlinkMacSystemFont, "Segoe UI",
    Roboto, Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
}

body {
  overflow-x: hidden;
  font-weight: 500 !important;
}

::-webkit-scrollbar {
  width: 10px;
}

::-webkit-scrollbar-track {
  // background-color: rgba(darkgrey, 0.3);
  border-radius: 5px;
}

::-webkit-scrollbar-thumb {
  border-radius: 5px;
  background-color: grey;
}

@font-face {
  font-family: "futura";
  src: url("../assets/Futura\ PT\ Bold.ttf") format("truetype"); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
  //  url('myfont.ttf') format('truetype'); /* Chrome 4+, Firefox 3.5, Opera 10+, Safari 3—5 */
}

// Imports
@import "./components/GlobalFilter";
@import "./components/Table";
@import "./components/DeleteDialog";
@import "./components/Sidebar";
@import "./components/DataDetailOnPopup";
@import "./components/DataOnGridLayout";
@import "./components/Profile";
@import "./components/ProfileUserList";
@import "./components/Dropdown";
@import "./components/CropProfilePicture";
@import "./components/NotFound";
@import "./components/LayoutSwitcher";
@import "./components/GeneralClasses";
@import "./components/Cockpit";
@import "./components/SignInAndUp";
@import "./components/DivIcon";
@import "./components/InlineTextField";
@import "./components/Modal";
@import "./components/CustomInput";
@import "./components/Navbar";
@import "./components/FiltersNavbar";
@import "./components/Home";
@import "./components/SelectDistrict";
@import "./components/Dashboard";
@import "./components/DashboardInfoBlock";
@import "./components/DashboardDataSummaryItem";
@import "./components/DistrictUserDashboard";
@import "./components/SchoolMap";
@import "./components/SchoolMapControlBtn";
@import "./components/SchoolMapPlaygroundTools";
@import "./components/SchoolSVGMap";
@import "./components/SchoolMapSelectEntity";
@import "./components/BuildingReportToPrintWithMap";
// @import "./components/SchoolMapSidebarToolsBtn";
@import "./components/BuildingSystemsTable";

@media screen and (max-width: 991px) {
  div.cockpit {
    width: 100vw;
    height: 600px;
    display: none !important;
  }

  .login-form-container {
    height: auto;
  }

  .logo-container {
    display: none;
  }

  .modal-box {
    max-width: 80%;
    max-height: 560px;
  }

  div.modal-content {
    width: 70% !important;
  }
}

@media screen and (min-width: 1439px) {
  div.cockpit {
    .feature-photo-container {
      width: 50% !important;
      margin-bottom: 6rem !important;
    }
    .slogan {
      width: 90%;
      font-size: 3.2rem;
    }
  }

  .school-map-svg__container-1 {
    height: 90vh !important;
  }

  .school-map__playground {
    height: 90vh;
  }
}

@media screen and (min-width: 991px) {
  div.cockpit {
    .feature-photo-container {
      width: 45%;
    }
  }

  .signinForm__topLogo {
    display: none;
  }
}

@media screen and (max-width: 991px) {
  .form {
    width: 100vw;
  }
}

@media screen and (max-width: 576px) {
  .form {
    .form-control {
      width: 21rem;
    }
  }

  .input-control-small {
    width: 17rem;
  }

  .icon-input-small {
    width: 21rem;
  }

  .input-control {
    width: 18rem;
  }

  button.btn-custom {
    width: 21rem;
  }

  .slogan {
    width: 65%;
    font-size: 2rem;
  }
  // }

  .modal-box {
    max-width: 90%;
  }

  div.modal-content {
    // padding: ;
    width: 90% !important;
    padding: 2.5rem 0.7rem !important;
  }
}

@media screen and (min-width: 576px) {
  .cockpit {
    .feature-photo-container {
      width: 50%;
    }

    .slogan {
      width: 55%;
      font-weight: 200;
    }
  }
}

@media screen and (min-width: 720px) {
  .cockpit {
    .feature-photo-container {
      width: 35%;
    }
  }
}

@media screen and (max-width: 420px) {
  .modal-box {
    max-width: 99%;
  }
}
