.buildingReport__container {
  display: none;
  visibility: hidden;
}

@media print {
  .buildingReport__container {
    display: flex;
    visibility: visible;
  }
}
