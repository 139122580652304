.cockpit {
  background-color: white;
  color: #fff;
  height: 100vh;
  width: 65vw;

  .upper-part {
    // height: 50%;
    // text-align: center;
    gap: 1rem;
  }

  p {
    width: 90%;
    margin: 0;
    padding: 0 2rem;
    font-weight: 500;
  }

  .feature-photo-container {
    width: 40%;
    // margin-right: 2rem;
    // background: url("../assets/UC1.svg");
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
  }
}

.slogan {
  width: 100%;
  text-align: center;
  color: #777;
  font-size: 2.5rem;
  font-family: "futura";
  font-weight: lighter;
}

.logo-container {
  position: absolute;
  left: 0;
  top: 0;
  margin-left: 2rem;
  margin-top: 2rem;

  .logo {
    width: 2.5rem;
  }
}
