.home-content-container {
  padding: 1rem 2rem;
  width: 100vw;
  height: 77.4vh;
  overflow-y: auto;
}

.switcher-btn {
  border: 0;
  color: #555;
  margin-left: 10px;
  border-radius: 5px;
  padding: 5px 10px;
}

.switcher-btn-active {
  color: white;
  background-color: $primary;
}

.switcher-btn:focus {
  outline: 0;
}

.map-switchers {
  position: absolute;
  top: 0;

  width: 100%;
  display: flex;
  justify-content: center;
  margin-top: 2rem;
  z-index: 500;
}

@media (max-width: 800px) {
  .switcherBtn__label {
    // color: inherit;
    display: none;
  }
}
