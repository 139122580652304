.school-map-svg__container {
  display: flex;
  height: 70vh;
  width: 70vw;
  margin-top: 1.5rem;
  justify-content: center;
  // outline: 1px solid $primary;
}

.school-map-svg__container-1 {
  // display: flex;

  // justify-content: center;
  height: 84vh;
  padding: 1px;
  // border: 1px solid dodgerblue;

  // @extend .school-map-svg__container;
  // width: 52vw;
}

// .cls-013:hover {
//   stroke: yellow;
//   stroke-width: 3px;
//   cursor: pointer;
// }

.cls-7:hover {
  stroke: yellow;
  stroke-width: 1px;
  cursor: pointer;
}

.cls-7-active {
  stroke: yellow !important;
  stroke-width: 1px !important;
  cursor: pointer !important;
}

// .cls-2:hover,
// .cls-1:hover,
// .cls-n:hover,
// .cls-3:hover,
// .cls-5:hover,
.cls-6:hover {
  stroke: yellow;
  stroke-width: 4px;
  cursor: pointer;
}

.cls-n-active {
  stroke: yellow !important;
  stroke-width: 4px !important;
  cursor: pointer !important;
}

.cls-3-active {
  stroke: yellow !important;
  stroke-width: 4px !important;
  cursor: pointer !important;
}

.cls-6-active {
  stroke: yellow !important;
  stroke-width: 2px !important;
  cursor: pointer !important;
}

.svg-map__info-block {
  margin: 5px;
  color: #333;
  width: 95%;
  // position: absolute;
  // top: 0;
  // right: 0px;
  // border: 1px solid dodgerblue;
  // text-align: center;
  img {
    width: 100%;
    object-fit: cover;
    border-radius: 5px;
    // border-top-left-radius: 5px;
    // border-top-right-radius: 5px;
  }
}

.info-block__description {
  // padding: 0.8rem 0.2rem 0.5rem;
  background-color: rgba($color: white, $alpha: 0.7);
}

.SVGMapInfo__infoPiece__container {
  border: 1px solid #f2f2f2;
  border-radius: 2px;
  font-size: 10px;
  text-align: justify;
  margin-bottom: 5px;
  display: flex;
  align-items: center;
 
}

.SVGMapInfo__label {
  display: inline-block;
  padding: 5px;
  padding-right: 0;
  background-color: #f2f2f2;
  width: 40%;
  border-radius: 2px;
  margin-right: 7px;
  font-weight: bold;
}

.schoolMap__printBtn__container {
  position: absolute;
  top: 10px;
  right: 0;
  // z-index: 1000;
}

.schoolMap__printBtn {
  width: 40px;
  height: 40px;
  background-color: #f3f3f3;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  margin-right: 10px;
  cursor: pointer;
}

@media print {
  .school-map-svg__container-1 {
    margin: 1rem;
  }
}
