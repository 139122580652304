.text-tiny {
  font-size: 14px;
}

.text-custom-primary {
  color: $primary;
}

a.custom-link {
  text-decoration: none;
  color: $primary;
}

.ml-custom-1 {
  margin-left: 0.4rem;
}

.ml-custom-2 {
  margin-left: 1rem;
}

.mb-custom-3 {
  margin-bottom: 1.2rem !important;
}

.mb-custom-5 {
  margin-bottom: 2.5rem !important;
}

.text-custom-danger {
  color: rgba($color: $warning, $alpha: 0.7);
}

.text-custom-danger:hover {
  color: $warning;
}

.modal-btn-custom {
  display: inline-block;
  color: #212529;
  text-align: center;
  text-decoration: none;
  vertical-align: middle;
  background-color: transparent;
  border: 1px solid transparent;
  padding: 0.375rem 0.75rem;
  font-size: 13px;
  border-radius: 5px;
  transition: width 0.15s ease-in-out, color 0.15s ease-in-out,
    background-color 0.15s ease-in-out, border-color 0.15s ease-in-out,
    box-shadow 0.15s ease-in-out;
}

.btn-custom-light {
  // color: #fff;
  background-color: #fff;
  border-color: #fff;
}

.btn-custom-primary {
  color: #fff;
  background-color: $primary;
  border-color: $primary;
}

button:disabled {
  cursor: not-allowed;
}

.gradient-title {
  background: linear-gradient(to right, #1fcfc3 27%, #cfa970 59%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.login-form-container {
  height: 100vh;
}

.google-map-container {
  width: 100vw;
  // height: 91.5vh;
  height: 83.1vh;
}

.places__list {
  position: absolute;
  top: 2rem;
  left: 0;
  right: 0;
  background-color: #fff;
  border: 1px solid #ccc;
  border-radius: 3px;
}

.places__list__item {
  padding: 0.3rem 0.7rem;
  cursor: pointer;
}

.places__list__item:hover {
  background-color: #eee;
}

.drawpolygon__measurement {
  position: absolute;
  top: 0;
  left: 0;
  margin-top: 3.5rem;
  margin-left: 0.6rem;
  padding: 1rem;
  z-index: 500;
  // color: white;
  background-color: white;
  border-radius: 3px;
}

.marker__container {
  width: 30px;
  height: 30px;
  margin: -20px -15px;
  border-radius: 100%;
  border: 1.5px solid #4fc9da;
  color: #4fc9da;
  font-size: 1rem;
  background-color: rgba($color: white, $alpha: 0.7);
}

.bg-custom-primary {
  background-color: $primary;
  color: white;
}

.text-custom-secondary {
  color: #6c757d;
}

.no-underline {
  text-decoration: none;
}

.assessment__detail__noteContainer {
  p {
    margin-bottom: 0 !important;
  }
}

.mapnavbar__clipboardBtn {
  display: flex;
  height: 30px;
  width: 30px;
  justify-content: center;
  align-items: center;
  background-color: #f0f0ef;
  border-radius: 5px;
  font-size: 14px;
}

.download__link {
  text-decoration: none;
  color: $primary;
}

// loading Dots

.loading__dots {
  width: 56px;
  height: 26.9px;
  background: radial-gradient(circle closest-side, #4fc9da 90%, #0000) 0% 50%,
    radial-gradient(circle closest-side, #4fc9da 90%, #0000) 50% 50%,
    radial-gradient(circle closest-side, #4fc9da 90%, #0000) 100% 50%;
  background-size: calc(100% / 3) 13.4px;
  background-repeat: no-repeat;
  animation: loading__dots-7ar3yq 1.2s infinite linear;
}

@keyframes loading__dots-7ar3yq {
  20% {
    background-position: 0% 0%, 50% 50%, 100% 50%;
  }

  40% {
    background-position: 0% 100%, 50% 0%, 100% 50%;
  }

  60% {
    background-position: 0% 50%, 50% 100%, 100% 0%;
  }

  80% {
    background-position: 0% 50%, 50% 50%, 100% 100%;
  }
}

@media print {
  .hidden-custom-print {
    // visibility: hidden !important;
    display: none !important;
  }

  .map__overlay__layer {
    margin-left: -75px;
    margin-top: 62px;
    // width: 100vw !important;
    // height: 100vh !important;
  }

  // .schoo-map__palayground-main {
  // display: flex;
  // margin: 4rem auto !important;
  // width: 100vw !important;
  // padding: 0 !important;
  // margin: 0px !important;
  // }

  // .school-map-svg__container-1 {
  // height: 85vh !important;
  // width: 52vw !important;
  // }
}
