.map-navbar {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 45px;
  background-color: snow;
  border-bottom: 1px solid #f3f3f3;
}

.map-navbar-filter-container {
  @extend .map-navbar;
  justify-content: start;
}

.map-navbar-select {
  margin-left: 15px;
  border-width: 0;
  width: 11rem;
  // padding: 0.25rem 0.5rem;
  padding: 7px 12px;
  text-overflow: ellipsis;
  border: 1px solid $input-background;
  border-radius: 20px;
  color: $secondary;
  color: #555;
  font-size: 14px;
}

.map-navbar-select:focus {
  outline: 0;
}

.map-navbar-select:disabled {
  opacity: 0.7;
  cursor: not-allowed;
}

.used-filter {
  border: 1px solid $primary !important;
}

div.active-filter {
  background-color: $primary !important;
  color: white !important;
}

.map-filter-menu {
  cursor: pointer;
  margin-top: 1px;
  background-color: snow;
  border: 1px solid #e3e3e3;
  border-radius: 5px;
  width: 11rem;
  padding: 0.5rem;
  font-size: 14px;
  color: #555;
}

.map-filter-menu-item {
  padding: 7px 12px;
  border-bottom: 1px solid #f3f3f3;
  color: #555;
}

.map-filter-menu-item:last-of-type {
  border-bottom: 0;
}

$curved-btn-color: #fc5185;
.curved-button {
  font-size: 12px;
  white-space: nowrap;
  width: 1.8rem;
  border: 1px solid transparent;
  border-radius: 30px;
  padding: 5px 8px;
  transition: width 0.15s ease-in-out;
  border: 0;
  outline: 0;
  color: $curved-btn-color;
  background-color: rgba($color: $curved-btn-color, $alpha: 0.07);
  transition: 0.3s;
}

.curved-button:hover {
  background-color: rgba($color: $curved-btn-color, $alpha: 0.15);
}

// .curved-button:hover .filter-clear-btn-text {
//   visibility: visible;
// }

// .filter-clear-btn-text {
//   visibility: hidden;
//   transition: visibility 0.15s ease-in-out;
// }
