.profile-container {
  padding: 0rem 12% 2rem;
  height: 90vh;
  color: #585858;
  overflow-y: auto;
  overflow-x: hidden;
}

.account-info {
  display: flex;
  align-items: center;
  gap: 3rem;
  // justify-content: space-between;
  padding: 0.8rem 0.8rem;
  background-color: rgba($color: snow, $alpha: 0.3);
  border: 1px solid #eee;
  border-radius: 5px;
  margin-bottom: 4.5rem;
  position: relative;

  .profile-info-photo {
    object-fit: cover;
    border-radius: 10px;
    width: 150px;
    height: 150px;
  }

  .profile-details {
    display: flex;
    font-size: 12px;
    margin-right: 1rem;
  }
}

.profile-info-avatar-container {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  background-color: #f1f1f1;
  border-radius: 100%;
  font-size: 9px;
  height: 20px;
  width: 20px;
}

.profile-secton-title {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1.5rem 0rem;
}

.profile-user-item-avatar {
  @extend .profile-info-avatar-container;
  margin: 0.7rem 0.7rem;

  img {
    height: 35px;
    width: 35px;
    border-radius: inherit;
    object-fit: cover;
  }
}

.profile-overlay-btn {
  border: 1px solid transparent;
  border-radius: 5px;
  height: 2rem;
  padding: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 2rem;
  width: 2rem;
  border-radius: 100%;

  // width: 6rem;
}

.overlay-btn-primary {
  background-color: rgba($color: $primary, $alpha: 0.7);
  color: white;
}

.overlay-btn-delete {
  background-color: rgba(220, 53, 69, 0.7);
  color: white;
  cursor: pointer;
}

.overlay-btn-delete:hover {
  background-color: rgb(190, 53, 69);
  // background-color: white;
  // color: rgb(190, 53, 69);
}

.overlay-btn-primary:hover {
  background-color: $primary;
  // background-color: white;
  // color: $primary;
  // border: 1px solid #eee;
}

.profile-picutre-overlay {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  // flex-direction: column;
  opacity: 0;
  position: absolute;
  top: 0;
  left: 0;
  border-radius: 10px;
  height: 100%;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  font-size: 12px;
  transition: 0.3s;
}

.profile-picutre-overlay:hover {
  opacity: 1;
}

.profile-title-badge {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  height: 20px;
  width: 20px;
  border-radius: 100%;
  margin-left: 5px;
  font-size: 13px;

  background-color: $primary;
  color: white;
}

.profile__actionbuttons {
  position: absolute;
  top: 10px;
  right: 10px;
  color: #888;
  font-size: 14px;
}

.profile__actionbuttons_item {
  padding: 0.3rem;
  margin-left: 0.3rem;
}

.profile__actionbuttons_item:hover {
  color: #444;
}

@media screen and (max-width: 872px) {
  .account-info {
    gap: 2rem;
    .profile-info-photo {
      height: 108px;
      width: 108px;
    }
  }
}
