$table_radius: 5px;

.table-container {
  border: 1px solid #eee;
  border-radius: $table_radius;
}

table {
  // border-collapse: separate !important;
  border-radius: $table_radius;
  width: 100%;
  color: #555;
  font-size: 12px;
}

tr:first-child th:first-child {
  border-top-left-radius: $table_radius;
}
tr:first-child th:last-child {
  border-top-right-radius: $table_radius;
}
tr:last-child td:first-child {
  border-bottom-left-radius: $table_radius;
}
tr:last-child td:last-child {
  border-bottom-right-radius: $table_radius;
}

table td,
table th {
  // border: 1px solid #ddd;
  padding: 8px;
}

table tr:nth-child(even) {
  background-color: #f2f2f2;
}

table tr:hover {
  background-color: #ddd;
}

table th,
tfoot td {
  padding-top: 12px;
  padding-bottom: 12px;
  background-color: snow;
}

.pagination-btn {
  font-size: 10px;
  border: 1px solid transparent;
  padding: 3px 8px;
  border-radius: 100%;
}

.pagination-btn:focus {
  outline: 0;
}

.pagination-btn:hover {
  background-color: $primary;
  color: white;
}

.current-page {
  @extend .pagination-btn;
  background-color: $primary;
  color: white;
}

.page-size-dropdown {
  @extend .global-filter;
  padding: 5px 10px;
}

.action-btn {
  background-color: transparent;
  // font-size: 11px;
  border: 0;
  border-radius: 5px;
  padding: 0px 4px;
}

.action-btn:focus {
  outline: 0;
}

.table-divider {
  border-top: 7px dotted #efefef;
  // border-radius: 100%;
  margin: 2rem auto;
  width: 10vw;
}

.table-content-to-print__container {
  // @extend .table-container;
  display: none;
  padding: 1rem;
  h1 {
    text-align: center;
  }
}

.normalize-text-print {
  font-size: 13px;
  // font-weight: 300 !important;
  color: #444;
}

@media print {
  .table-content-to-print__container {
    display: block;
  }

  table {
    page-break-inside: avoid;
  }
}
