.buildingsystemtable__inputs {
  width: 100%;
  border: 0;
}

.buildingSystemsTable td {
  padding: 0;
  margin: 0;
  border: 1px solid #afafaf;
}

.buildingSystemsTable input:focus {
  outline: 1px solid dodgerblue;
}

.buildingSystemsTable tr:nth-child(even) input {
  background-color: #f2f2f2;
}
