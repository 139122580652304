.dataSummaryItem {
  display: flex;
  justify-content: space-between;
  font-size: 11px;
  margin: 6px 8px;
}

.dataSummaryItem__total {
  border-top: 1px solid black;
  font-weight: bolder;
  padding-top: 10px;
  // color: #d0d2da !important;
}

.dashboard__sidebar__item {
  display: flex;
  gap: 15px;
  align-items: center;
  font-size: 12px;
  // color: #454545;
  color: #80828a;
  text-align: left !important;
  padding: 0.8rem 1rem;
  width: 13rem;
  transition: 0.15s;
  cursor: pointer;
  text-decoration: none !important;

  .icon_container {
    font-size: 15px;
  }
}

.dashboard__sidebar__item:hover {
  color: #551fff;
  background-color: #f3f0ff;
}

.dashboard__sidebar__activeItem {
  color: #551fff;
  background-color: #f3f0ff;
}
