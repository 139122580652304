.districtUser__basicInfo__container {
  border-radius: 10px;
  width: 100%;
  background-color: #fff;
  display: flex;
  // height: 210px;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  // padding: 1.8rem;
}

.dashboard__info__container {
  width: 680px;
  // padding: 0.5rem 1rem;
}

.dashboard__chartsContainer {
  // border: 1px solid red;
  flex-grow: 1;
  // display: flex;
  // justify-content: center;
}

.charts__container {
  justify-content: center;
  align-items: flex-start;
  // margin-top: 0.5rem;
  // width: 720px;
  display: flex;
  flex-wrap: wrap;
  // flex-direction: column;
  // gap: 3rem;
}

.chart__item {
  padding-bottom: 1.5rem;
  // padding-left: 5px;
  // padding-right: 5px;
  margin-bottom: 1.5rem;
  // padding: 1rem;
  width: 310px;
  margin-left: 5px;
  border-radius: 10px;
  background-color: #fff;
  page-break-inside: avoid !important;
}

.chart__item__print {
  @extend .chart__item;
  margin-bottom: 0;
  padding-bottom: 0;
}

.dashboard__numbers {
  // display: flex;
  @extend .districtUser__basicInfo__container;
  justify-content: center;
  width: 660px;
  height: 110px;
  gap: 15px;
}

.dashboard__number__container {
  width: 200px;
  height: 70px;
  border-radius: 10px;
  background-color: #ffebef;
  padding: 10px 20px;

  h6 {
    font-size: 13px;
  }

  span {
    color: #15192c;
    font-size: 18px;
  }
}

.dashboard__details__container {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  gap: 1rem;
  margin-top: 3rem;
  margin-bottom: 3rem;
  align-items: flex-start;
}

.dashboard__detailItem__header {
  font-size: 12px;
  padding-bottom: 10px;
  border-bottom: 1px solid black;
  color: #454545;
  margin: 5px;
  margin-bottom: 10px;
}

.dashboard__detailItem {
  width: 310px;
  // border: 1px solid $primary;
  border-radius: 10px;
  background-color: #fff;
  // border-radius: 2px;
  margin-bottom: 1rem;
  padding: 1rem;
}

.dashboard__detailItem__print {
  @extend .dashboard__detailItem;
  margin-bottom: 0;
  padding-top: 0;
  padding-bottom: 0.7rem;
}

.dashboard__menu__container {
  background-color: #fff;
  height: 90vh;
  // width: 20vw;
  // width: 17rem;
  display: flex;
  padding: 0 2rem;
  flex-direction: column;
  align-items: center;

  img {
    width: 13rem;
    margin-top: 25px;
    margin-bottom: 15px;
    // border-radius: 10px;
  }
}

.custom__separtor {
  margin-top: 1.5rem;
  margin-bottom: 1.5rem;
  width: 2rem;
  border-top: 5px dotted #aaa;
}

@media screen and (min-width: 1644px) {
  .chart__item {
    margin-right: 15px;
  }
}

@media print {
  .dashboard__details__container {
    margin-top: 1rem;
  }

  .dashboard__detailItem {
    page-break-inside: avoid;
  }

  .dashboard__detailItem__print {
    page-break-inside: avoid;
  }

  .chart__item {
    page-break-inside: avoid;
  }
}
