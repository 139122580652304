h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "Rubik", "Inter", -apple-system, BlinkMacSystemFont, "Segoe UI",
    Roboto, Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
}

body {
  overflow-x: hidden;
  font-weight: 500 !important;
}

::-webkit-scrollbar {
  width: 10px;
}

::-webkit-scrollbar-track {
  border-radius: 5px;
}

::-webkit-scrollbar-thumb {
  border-radius: 5px;
  background-color: grey;
}

@font-face {
  font-family: "futura";
  src: url("../assets/Futura PT Bold.ttf") format("truetype"); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
.global-filter,
.page-size-dropdown {
  border: 1px solid #e3e3e3;
  border-radius: 5px;
  padding: 5px 10px;
  font-size: 12px;
}

.global-filter:focus,
.page-size-dropdown:focus {
  outline: 0;
  border: 1px solid #4fc9da;
}

.table-container {
  border: 1px solid #eee;
  border-radius: 5px;
}

table {
  border-radius: 5px;
  width: 100%;
  color: #555;
  font-size: 12px;
}

tr:first-child th:first-child {
  border-top-left-radius: 5px;
}

tr:first-child th:last-child {
  border-top-right-radius: 5px;
}

tr:last-child td:first-child {
  border-bottom-left-radius: 5px;
}

tr:last-child td:last-child {
  border-bottom-right-radius: 5px;
}

table td,
table th {
  padding: 8px;
}

table tr:nth-child(even) {
  background-color: #f2f2f2;
}

table tr:hover {
  background-color: #ddd;
}

table th,
tfoot td {
  padding-top: 12px;
  padding-bottom: 12px;
  background-color: snow;
}

.pagination-btn,
.current-page {
  font-size: 10px;
  border: 1px solid transparent;
  padding: 3px 8px;
  border-radius: 100%;
}

.pagination-btn:focus,
.current-page:focus {
  outline: 0;
}

.pagination-btn:hover,
.current-page:hover {
  background-color: #4fc9da;
  color: white;
}

.current-page {
  background-color: #4fc9da;
  color: white;
}

.page-size-dropdown {
  padding: 5px 10px;
}

.action-btn {
  background-color: transparent;
  border: 0;
  border-radius: 5px;
  padding: 0px 4px;
}

.action-btn:focus {
  outline: 0;
}

.table-divider {
  border-top: 7px dotted #efefef;
  margin: 2rem auto;
  width: 10vw;
}

.table-content-to-print__container {
  display: none;
  padding: 1rem;
}
.table-content-to-print__container h1 {
  text-align: center;
}

.normalize-text-print {
  font-size: 13px;
  color: #444;
}

@media print {
  .table-content-to-print__container {
    display: block;
  }
  table {
    page-break-inside: avoid;
  }
}
.dialog-wrapper {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

.dialog-backdrop {
  position: fixed;
  height: 100vh;
  width: 100vw;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 100;
  background-color: rgba(0, 0, 0, 0.5);
}

.dialog-box {
  overflow: hidden;
  position: fixed;
  margin: auto;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  max-height: 22rem;
  max-width: 25rem;
  overflow-y: auto;
  background-color: white;
  box-shadow: 0 0 10px rgba(255, 255, 255, 0.7);
  z-index: 10000;
  border-radius: 5px;
  font-size: 14px !important;
}

.dialog-message {
  text-align: justify;
}

.dialog-input {
  width: 23rem;
  padding: 5px 7px;
  border-radius: 5px;
  border: 1px solid #ddd;
}

.dialog-input:focus {
  outline: 0;
}

.dialog-header {
  margin: 0.5rem 0 1rem;
}

.delete-dialoge-warning {
  padding: 20px 0;
  border-top: 1px solid rgba(255, 0, 0, 0.4);
  border-bottom: 1px solid rgba(255, 0, 0, 0.4);
  background-image: linear-gradient(rgba(255, 0, 0, 0.2), rgba(255, 0, 0, 0.2));
}

.btn-delete-dialog {
  border: 1px solid #ccc;
  border-radius: 4px;
}

.btn-delete {
  background-color: rgb(220, 53, 69);
  color: white;
  cursor: pointer;
}

.btn-delete:hover {
  background-color: rgb(190, 53, 69);
}

.btn-cancel {
  background-color: white;
}

.btn-cancel:hover {
  background-color: #ededed;
}

.btn-delete:disabled {
  opacity: 0.5;
  cursor: not-allowed;
}

.sidebar-container {
  width: 100%;
  height: 100%;
}

.sidebar-wrapper {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

.sidebar-backdrop {
  position: fixed;
  height: 100vh;
  width: 100vw;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 100;
  background-color: rgba(0, 0, 0, 0.5);
}

.sidebar-box {
  position: fixed;
  margin: auto;
  top: 0;
  right: 0;
  height: 100%;
  overflow-y: auto;
  background-color: white;
  box-shadow: 0 0 10px rgba(255, 255, 255, 0.25);
  z-index: 10000;
  border-radius: 5px;
}

.image-grid img {
  border-radius: 5px;
}

.image-grid {
  --gap: 16px;
  --num-cols: 2;
  --row-height: 125px;
  box-sizing: border-box;
  padding: var(--gap);
  display: grid;
  grid-template-columns: repeat(var(--num-cols), 1fr);
  grid-auto-rows: var(--row-height);
  gap: var(--gap);
}

.image-grid > img {
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
  object-fit: cover;
}

.image-grid-col-2 {
  grid-column: span 2;
}

.image-grid-row-2 {
  grid-row: span 2;
}

.data-detail-on-popup-container {
  border-radius: 3px;
  background-color: white;
  width: 15rem;
}
.data-detail-on-popup-container .img-container {
  border-top-left-radius: inherit;
  border-top-right-radius: inherit;
  height: 100px;
  background-repeat: no-repeat;
  text-align: center;
}
.data-detail-on-popup-container .img-container img {
  height: 100px;
  border-radius: inherit;
  -o-object-fit: contain;
  object-fit: contain;
}
.data-detail-on-popup-container .data-detail {
  padding: 0.6rem;
}
.data-detail-on-popup-container .data-detail .data-detail-item {
  padding: 0.15rem 0;
}

.data-on-grid-container {
  --gap: 20px;
  --num-cols: 5;
  --row-height: 270px;
  height: 93%;
  box-sizing: border-box;
  padding: 2rem 2rem;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(240px, 270px));
  grid-auto-rows: var(--row-height);
  gap: var(--gap);
}

.data-on-grid-item {
  width: 100%;
  height: 100%;
  /* border: 1px solid #f3f3f3; */
  border-radius: 3px;
  color: #585858;
  padding: 0 !important;
  position: relative;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
}

.data-on-grid-item img {
  margin: 0 !important;
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
  object-fit: cover;
  border-top-left-radius: inherit;
  border-top-right-radius: inherit;
}

.data-on-grid-item span {
  height: 75%;
  border-radius: inherit;
}

.data-on-grid-item-detail {
  padding: 10px;
}
.data-on-grid-item-detail h6 {
  font-size: 13px;
  font-weight: bold;
  font-family: "Inter" !important;
  margin: 0;
  padding: 0;
  margin-bottom: 0.22rem;
}
.data-on-grid-item-detail p {
  font-size: 10px;
}

.grid-layout-popup {
  position: absolute;
  top: 30px;
  right: 2px;
  z-index: 1;
  border: 1px solid #f3f3f3;
  border-radius: 3px;
  background-color: white;
}

.grid-layout-popup-item {
  font-size: 12px;
  width: 100%;
  padding: 0.5rem 0.7rem;
  border: 0;
  outline: 0;
  cursor: pointer;
}

.grid-layout-popup-item:first-of-type {
  border-top-left-radius: inherit;
  border-top-right-radius: inherit;
}

.grid-layout-popup-item:last-of-type {
  border-bottom-left-radius: inherit;
  border-bottom-right-radius: inherit;
}

.grid-layout-popup-item:hover {
  background-color: #ddd;
}

@media screen and (max-width: 1270px) {
  .data-on-grid-container {
    --num-cols: 4;
  }
}
@media screen and (max-width: 1085px) {
  .data-on-grid-container {
    --num-cols: 3;
  }
}
@media screen and (max-width: 905px) {
  .data-on-grid-container {
    --num-cols: 3;
  }
}
@media screen and (max-width: 767px) {
  .data-on-grid-container {
    --num-cols: 2;
  }
}
@media screen and (max-width: 510px) {
  .data-on-grid-container {
    --num-cols: 1;
  }
}
.profile-container {
  padding: 0rem 12% 2rem;
  height: 90vh;
  color: #585858;
  overflow-y: auto;
  overflow-x: hidden;
}

.account-info {
  display: flex;
  align-items: center;
  gap: 3rem;
  padding: 0.8rem 0.8rem;
  background-color: rgba(255, 250, 250, 0.3);
  border: 1px solid #eee;
  border-radius: 5px;
  margin-bottom: 4.5rem;
  position: relative;
}
.account-info .profile-info-photo {
  -o-object-fit: cover;
  object-fit: cover;
  border-radius: 10px;
  width: 150px;
  height: 150px;
}
.account-info .profile-details {
  display: flex;
  font-size: 12px;
  margin-right: 1rem;
}

.profile-info-avatar-container,
.profile-user-item-avatar {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  background-color: #f1f1f1;
  border-radius: 100%;
  font-size: 9px;
  height: 20px;
  width: 20px;
}

.profile-secton-title {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1.5rem 0rem;
}

.profile-user-item-avatar {
  margin: 0.7rem 0.7rem;
}
.profile-user-item-avatar img {
  height: 35px;
  width: 35px;
  border-radius: inherit;
  -o-object-fit: cover;
  object-fit: cover;
}

.profile-overlay-btn {
  border: 1px solid transparent;
  border-radius: 5px;
  height: 2rem;
  padding: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 2rem;
  width: 2rem;
  border-radius: 100%;
}

.overlay-btn-primary {
  background-color: rgba(79, 201, 218, 0.7);
  color: white;
}

.overlay-btn-delete {
  background-color: rgba(220, 53, 69, 0.7);
  color: white;
  cursor: pointer;
}

.overlay-btn-delete:hover {
  background-color: rgb(190, 53, 69);
}

.overlay-btn-primary:hover {
  background-color: #4fc9da;
}

.profile-picutre-overlay {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  opacity: 0;
  position: absolute;
  top: 0;
  left: 0;
  border-radius: 10px;
  height: 100%;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  font-size: 12px;
  transition: 0.3s;
}

.profile-picutre-overlay:hover {
  opacity: 1;
}

.profile-title-badge {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  height: 20px;
  width: 20px;
  border-radius: 100%;
  margin-left: 5px;
  font-size: 13px;
  background-color: #4fc9da;
  color: white;
}

.profile__actionbuttons {
  position: absolute;
  top: 10px;
  right: 10px;
  color: #888;
  font-size: 14px;
}

.profile__actionbuttons_item {
  padding: 0.3rem;
  margin-left: 0.3rem;
}

.profile__actionbuttons_item:hover {
  color: #444;
}

@media screen and (max-width: 872px) {
  .account-info {
    gap: 2rem;
  }
  .account-info .profile-info-photo {
    height: 108px;
    width: 108px;
  }
}
.table-container {
  border: 1px solid #eee;
  border-radius: 5px;
}

table {
  border-radius: 5px;
  width: 100%;
  color: #555;
  font-size: 12px;
}

tr:first-child th:first-child {
  border-top-left-radius: 5px;
}

tr:first-child th:last-child {
  border-top-right-radius: 5px;
}

tr:last-child td:first-child {
  border-bottom-left-radius: 5px;
}

tr:last-child td:last-child {
  border-bottom-right-radius: 5px;
}

table td,
table th {
  padding: 8px;
}

table tr:nth-child(even) {
  background-color: #f2f2f2;
}

table tr:hover {
  background-color: #ddd;
}

table th,
tfoot td {
  padding-top: 12px;
  padding-bottom: 12px;
  background-color: snow;
}

.custom-dropdown,
.custom-dropdown-1 {
  cursor: pointer;
  width: 13rem;
  font-size: 12px;
  color: #555;
  border-radius: 5px;
  /* border: 1px solid #f3f3f3; */
  transition: all 0.15s linear;
  position: relative;
  background-color: #fff;
}

.custom-dropdown-margin {
  margin-left: 1.3rem;
}

.custom-dropdown-disabled {
  opacity: 0.7;
  cursor: not-allowed;
}

.custom-dropdown .custom-dropdown-btn,
.custom-dropdown-1 .custom-dropdown-btn,
.custom-dropdown .custom-dropdown-btn-1,
.custom-dropdown-1 .custom-dropdown-btn-1 {
  white-space: nowrap;
  padding: 5px 10px;
  background-color: #fff;
  box-shadow: 3px 3px 10px 6px rgba(255, 255, 255, 0.06);
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-radius: 5px;
  text-overflow: ellipsis;
  overflow-x: hidden;
  border: 1px solid #33415585;
  color: #334155;
}

.custom-dropdown .custom-dropdown-content,
.custom-dropdown-1 .custom-dropdown-content {
  position: absolute;
  width: 13rem;
  top: 1.85rem;
  z-index: 100000000;
  background: #fff;
  box-shadow: 3px 3px 10px 6px rgba(255, 255, 255, 0.06);
  color: #555;
  font-size: 12px;
  border-radius: 5px;
  border: 1px solid #e1e1e1;
}

.custom-dropdown-item {
  border: 0;
  outline: 0;
  display: flex;
  justify-content: space-between;
  padding: 5px 10px;
  cursor: pointer;
}

.custom-dropdown-item:last-of-type {
  border-bottom-left-radius: inherit;
  border-bottom-right-radius: inherit;
}

.custom-dropdown-item:hover {
  background-color: #e1e1e1;
}

.custom-dropdown-search-box {
  box-sizing: border-box;
  display: inline-block;
  width: 92%;
  margin: 0.5rem 4%;
  padding: 0.5rem;
  border: 1px solid #ddd;
  border-radius: 5px;
}

.custom-dropdown-search-box:focus {
  outline: 0;
  border: 1px solid #4fc9da;
}

.custom-dropdown-btn-1 {
  border-radius: 5px;
}

.custom-dropdown-1 {
  display: inline-block;
  border-radius: 5px;
  width: -moz-fit-content;
  width: fit-content;
}

.cropper-container {
  height: 90vh;
  padding: 1.5rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.cropper-container .cropper {
  display: flex;
  gap: 1.5rem;
  justify-content: center;
  height: 60vh;
}
.cropper-container .cropper img {
  -o-object-fit: cover;
  object-fit: cover;
}

.crop-profile-btn {
  border: 1px solid transparent !important;
  border-radius: 5px;
  outline: 0;
  padding: 0.7rem 1rem;
}

.crop-profile-upload-btn {
  border: 1px solid rgba(79, 201, 218, 0.8) !important;
  background-color: rgba(79, 201, 218, 0.9);
  color: white;
}

.crop-profile-upload-btn:hover {
  background-color: #4fc9da;
}

.crop-profile-btn-clear {
  border: 1px solid #ddd !important;
  background-color: white !important;
}

.crop-profile-btn:disabled {
  opacity: 0.8;
  cursor: not-allowed;
}

.profilepic__input {
  position: absolute;
  left: -999px;
  width: 1px;
  height: 1px;
  opacity: 0;
  visibility: hidden;
}

.profilepic__label {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  padding: 10px 15px;
  background-color: #4fc9da;
  border-radius: 30px;
  cursor: pointer;
  transition: 0.3s;
}

.profilepic__text {
  color: white;
}

.profilepic__icon {
  margin-right: 15px;
  color: white;
}

.profilepic__label:hover {
  transform: translateY(-5px);
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
}

.not-found {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}

.layout__switcher,
.navbar__avatar__popup__menu-item .navbar__avatar__popup__menu__icon {
  display: inline-block;
  /* padding: 0.3rem 0.5rem; */
  /* background-color: #f8f8f8; */
  color: #888;
  border-radius: 5px;
  transition: 0.3s;
}

/* .layout__switcher:hover, .navbar__avatar__popup__menu-item .navbar__avatar__popup__menu__icon:hover {
  background-color: #f1f1f1;
} */

.option-disabled {
  opacity: 0.4;
  cursor: not-allowed !important;
}

.option-active {
  color: #c06c84;
  background-color: rgba(192, 108, 132, 0.1);
}

.option-active:hover {
  background-color: rgba(192, 108, 132, 0.15);
}

.text-tiny {
  font-size: 14px;
}

.text-custom-primary {
  color: #4fc9da;
}

a.custom-link {
  text-decoration: none;
  color: #4fc9da;
}

.ml-custom-1 {
  margin-left: 0.4rem;
}

.ml-custom-2 {
  margin-left: 1rem;
}

/* .mb-custom-3 {
  margin-bottom: 1.2rem !important;
} */

.mb-custom-5 {
  margin-bottom: 2.5rem !important;
}

.text-custom-danger {
  color: rgba(220, 53, 69, 0.7);
}

.text-custom-danger:hover {
  color: #dc3545;
}

.modal-btn-custom {
  display: inline-block;
  color: #212529;
  text-align: center;
  text-decoration: none;
  vertical-align: middle;
  background-color: transparent;
  border: 1px solid transparent;
  padding: 0.375rem 0.75rem;
  font-size: 13px;
  border-radius: 5px;
  transition: width 0.15s ease-in-out, color 0.15s ease-in-out,
    background-color 0.15s ease-in-out, border-color 0.15s ease-in-out,
    box-shadow 0.15s ease-in-out;
}

.btn-custom-light {
  background-color: #fff;
  border-color: #fff;
}

.btn-custom-primary {
  color: #fff;
  background-color: #334155;
  border-color: #334155;
}

button:disabled {
  cursor: not-allowed;
}

.gradient-title {
  background: linear-gradient(to right, #1fcfc3 27%, #cfa970 59%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.login-form-container {
  height: 100vh;
}

.google-map-container {
  width: 100vw;
  height: 83.1vh;
}

.places__list {
  position: absolute;
  top: 2rem;
  left: 0;
  right: 0;
  background-color: #fff;
  border: 1px solid #ccc;
  border-radius: 3px;
}

.places__list__item {
  padding: 0.3rem 0.7rem;
  cursor: pointer;
}

.places__list__item:hover {
  background-color: #eee;
}

.drawpolygon__measurement {
  position: absolute;
  top: 0;
  left: 0;
  margin-top: 3.5rem;
  margin-left: 0.6rem;
  padding: 1rem;
  z-index: 500;
  background-color: white;
  border-radius: 3px;
}

.marker__container {
  width: 30px;
  height: 30px;
  margin: -20px -15px;
  border-radius: 100%;
  border: 1.5px solid #4fc9da;
  color: #4fc9da;
  font-size: 1rem;
  background-color: rgba(255, 255, 255, 0.7);
}

.bg-custom-primary {
  background-color: #4fc9da;
  color: white;
}

.text-custom-secondary {
  color: #6c757d;
}

.no-underline {
  text-decoration: none;
}

.assessment__detail__noteContainer p {
  margin-bottom: 0 !important;
}

.mapnavbar__clipboardBtn {
  display: flex;
  height: 30px;
  width: 30px;
  justify-content: center;
  align-items: center;
  background-color: #f0f0ef;
  border-radius: 5px;
  font-size: 14px;
}

.download__link {
  text-decoration: none;
  color: #4fc9da;
}

.loading__dots {
  width: 56px;
  height: 26.9px;
  background: radial-gradient(
        circle closest-side,
        #4fc9da 90%,
        rgba(0, 0, 0, 0)
      )
      0% 50%,
    radial-gradient(circle closest-side, #4fc9da 90%, rgba(0, 0, 0, 0)) 50% 50%,
    radial-gradient(circle closest-side, #4fc9da 90%, rgba(0, 0, 0, 0)) 100% 50%;
  background-size: 33.3333333333% 13.4px;
  background-repeat: no-repeat;
  animation: loading__dots-7ar3yq 1.2s infinite linear;
}

@keyframes loading__dots-7ar3yq {
  20% {
    background-position: 0% 0%, 50% 50%, 100% 50%;
  }
  40% {
    background-position: 0% 100%, 50% 0%, 100% 50%;
  }
  60% {
    background-position: 0% 50%, 50% 100%, 100% 0%;
  }
  80% {
    background-position: 0% 50%, 50% 50%, 100% 100%;
  }
}
@media print {
  .hidden-custom-print {
    display: none !important;
  }
  .map__overlay__layer {
    margin-left: -75px;
    margin-top: 62px;
  }
}
.cockpit {
  background-color: white;
  color: #fff;
  height: 100vh;
  width: 65vw;
}
.cockpit .upper-part {
  gap: 1rem;
}
.cockpit p {
  width: 90%;
  margin: 0;
  padding: 0 2rem;
  font-weight: 500;
}
.cockpit .feature-photo-container {
  width: 40%;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
}

.slogan {
  width: 100%;
  text-align: center;
  color: #777;
  font-size: 2.5rem;
  font-family: "futura";
  font-weight: lighter;
}

.logo-container {
  position: absolute;
  left: 0;
  top: 0;
  margin-left: 2rem;
  margin-top: 2rem;
}
.logo-container .logo {
  width: 2.5rem;
}

.form {
  display: flex;
  flex-direction: column;
  background-color: snow;
  align-items: center;
  justify-content: center;
  width: 35vw;
  color: #333;
  height: 100vh;
  box-shadow: rgba(0, 0, 0, 0.05) 0px 1px 2px 0px;
}
.form .form-label {
  font-weight: 600;
}

.input-control,
.input-control-small {
  background-color: rgba(225, 225, 225, 0.2);
  border-radius: 0.8rem;
  display: block;
  width: 17rem;
  padding: 0.65rem 1rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
  background-clip: padding-box;
  border-width: 0;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  transition: border-color 0.15s ease-in-out, background-color 0.15s ease-in-out;
}

.input-control-small {
  width: 8.5rem;
}

.icon-input,
.icon-input-small {
  background-color: rgba(225, 225, 225, 0.6);
  border: 1px solid rgba(225, 225, 225, 0.6);
  border-radius: 10px;
  width: 21rem;
  transition: background-color 0.15s ease-in-out;
}

.icon-input-small {
  width: 10rem;
}

.input-control:focus,
.input-control-small:focus,
.icon-input:focus-within,
.icon-input-small:focus-within {
  outline: 0;
  border: 1 solid #e1e1e1;
  background-color: #e1e1e1;
}

button.btn-custom {
  padding: 0.65rem 1rem;
  border-radius: 10px;
  font-weight: 500;
  font-size: 0.8rem;
  width: 21rem;
}

button.btn-custom-primary {
  color: #fff;
  background-color: #334155;
  border-color: #334155;
}

button.btn-custom-primary:hover {
  color: #fff;
  background-color: #334155;
  border-color: #334155;
}

.text-bold {
  font-weight: 500;
}

.icon {
  height: 20px;
}

.sign-in-out-header {
  width: 400px;
  text-align: center;
}

.normalize-text {
  font-size: 0.8rem;
}

.error-text {
  font-size: 0.6rem;
  padding-top: 0.15rem !important;
  position: absolute;
}

div#terms-conditions {
  width: 21rem;
  word-wrap: break-word;
  text-align: justify;
  font-size: 0.6rem;
}

div#icon-button-label {
  text-align: left;
  padding-left: 1rem !important;
}

.inline-textfield-container {
  width: 21rem;
}

.modal-wrapper {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

.modal-backdrop {
  position: fixed;
  height: 100vh;
  width: 100vw;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 100;
  background-color: rgba(0, 0, 0, 0.2);
}

.modal-box {
  position: fixed;
  margin: auto;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  max-width: 1080px;
  max-height: 600px;
  overflow-y: scroll;
  height: auto;
  background-color: white;
  z-index: 10000;
  border-radius: 5px;
  overflow: hidden;
}

.modal-box::-webkit-scrollbar {
  width: 10px;
}

.modal-box::-webkit-scrollbar-track {
  background-color: rgba(169, 169, 169, 0.3);
  border-radius: 5px;
  margin-right: 5px;
}

.modal-box::-webkit-scrollbar-thumb {
  border-radius: 5px;
  background-color: grey;
}

div.modal-content {
  margin: 0 auto;
  padding: 2.5rem;
  /* width: 60% !important; */
  border-width: 0 !important;
}

div.modal-content-1 {
  margin: 0 auto;
  padding: 2.5rem;
  width: 95% !important;
  border-width: 0 !important;
}

div.modal-content-2 {
  margin: 0 auto;
  padding: 2.5rem;
  padding-top: 0;
  width: 75% !important;
  border-width: 0 !important;
}

.modal-icon {
  display: inline-block;
  padding: 0.5rem;
  background-color: rgba(79, 201, 218, 0.2);
  color: #4fc9da;
  font-size: 1.3rem;
  border-radius: 5px;
}

.form-on-modal-container {
  max-height: 73vh;
  overflow-x: auto;
  margin-right: 3px;
  margin-top: 3px;
}

.custom-input,
.custom-input-1,
.custom-select {
  display: block;
  width: 100%;
  padding: 0.375rem 0.75rem;
  font-size: 12px;
  font-weight: 400;
  color: #212529;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 5px;
}

.custom-select {
  padding: 0.375rem 2.25rem 0.375rem 0.75rem;
}

.custom-input:focus,
.custom-input-1:focus,
.custom-select:focus {
  outline: 0;
}

.custom-input:disabled,
.custom-input-1:disabled,
.custom-select:disabled {
  background-color: #e9ecef;
}

.input__label {
  font-size: "10px";
  margin-left: 1px;
  color: #555;
}

.custom-input-1 {
  width: 80%;
  display: inline-block;
}

.profile-avatar-container {
  cursor: pointer;
  border-radius: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 40px;
  width: 40px;
  padding: 0 !important;
  position: relative;
}

.profile-avatar {
  display: inline-block;
  height: 40px;
  width: 40px;
  border-radius: inherit;
  background-color: #999;

  margin: 0;
  -o-object-fit: contain;
  object-fit: contain;
}

.navbar__username {
  display: inline-block;
  margin-left: 0.5rem;
  font-weight: 1;
  font-size: 0.8rem;
  text-decoration: none;
  color: #555;
}

.custom-navbar {
  height: 80px;
  background-color: snow;
  border-bottom: 1px solid #f3f3f3;
  padding-left: 0 !important;
}

.custom-nav {
  align-items: center;
  /* height: 60px; */
}

.custom-nav-item {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: 0 5px;
  height: inherit;
  vertical-align: middle;
  font-size: 16px;
  font-weight: 500;
  text-decoration: none;
  transition: 0.15s;
  font-family: "Rubik";
  background-color: #334155;
  color: white;
}

.custom-nav-item:hover {
  /* background-color: #eee; */
  color: white;
}

.custom-nav-item-active {
  /* background-color: #eee; */
  color: white;
}

.navbar__avatar__popup {
  background-color: white;
  border-radius: 5px;
  border: 1px solid #f3f3f3;
  padding: 0;
  width: 150px;
  margin-right: 10px;
  position: absolute;
  left: -95px;
}

.navbar__avatar__popup__menu-item {
  padding: 5px 10px;
  color: #666;
  font-size: 14px;
  cursor: pointer;
}
.navbar__avatar__popup__menu-item:hover .navbar__avatar__popup__menu__icon {
  background-color: #eee;
}

.navbar__avatar__popup__menu-item:first-of-type {
  border-top-left-radius: inherit;
  border-top-right-radius: inherit;
}

.navbar__avatar__popup__menu-item:last-of-type {
  border-bottom-left-radius: inherit;
  border-bottom-right-radius: inherit;
}

.navbar__avatar__popup__menu-item a {
  text-decoration: none;
  color: inherit;
  border: 0;
}

.navbar__avatar__popup__menu-item a:hover {
  color: #666;
}

.navbar__avatar__popup__menu-item a:focus {
  outline: 0;
  border: 0;
}

.brand-logo {
  cursor: pointer;
  color: #9a9a9a;
  font-size: 25px;
}

.map-navbar {
  background-color: #334155;
}

.map-navbar,
.map-navbar-filter-container {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 50px;
  /* border-bottom: 1px solid #f3f3f3; */
  background-color: #fff;
}

.map-navbar-filter-container {
  justify-content: start;
  background-color: white;
  margin: 5px;
  height: 45px;
  border-radius: 5px;
  border: 1px solid #33415538;
}

.map-navbar-select {
  margin-left: 15px;
  border-width: 0;
  width: 11rem;
  padding: 7px 12px;
  text-overflow: ellipsis;
  border: 1px solid #e1e1e1;
  border-radius: 20px;
  color: #333;
  color: #555;
  font-size: 14px;
}

.map-navbar-select:focus {
  outline: 0;
}

.map-navbar-select:disabled {
  opacity: 0.7;
  cursor: not-allowed;
}

.used-filter {
  border: 1px solid #334555 !important;
}

div.active-filter {
  background-color: #33455513 !important;
  color: #334155 !important;
}

.map-filter-menu {
  cursor: pointer;
  margin-top: 1px;
  background-color: snow;
  border: 1px solid #e3e3e3;
  border-radius: 5px;
  width: 11rem;
  padding: 0.5rem;
  font-size: 14px;
  color: #555;
}

.map-filter-menu-item {
  padding: 7px 12px;
  border-bottom: 1px solid #33455513;
  color: #334555;
}

.map-filter-menu-item:last-of-type {
  border-bottom: 0;
}

.curved-button {
  font-size: 12px;
  white-space: nowrap;
  width: 1.8rem;
  border: 1px solid transparent;
  border-radius: 30px;
  padding: 5px 8px;
  transition: width 0.15s ease-in-out;
  border: 0;
  outline: 0;
  color: #fc5185;
  background-color: rgba(252, 81, 133, 0.07);
  transition: 0.3s;
}

/* .curved-button:hover {
  background-color: rgba(252, 81, 133, 0.15);
} */

.home-content-container {
  padding: 1rem 2rem;
  width: 100vw;
  height: 77.4vh;
  overflow-y: auto;
}

.switcher-btn {
  border: 0;
  color: #555;
  margin-left: 10px;
  border-radius: 5px;
  padding: 5px 10px;
}

.switcher-btn-active {
  color: white;
  background-color: #4fc9da;
}

.switcher-btn:focus {
  outline: 0;
}

.map-switchers {
  position: absolute;
  top: 0;
  width: 100%;
  display: flex;
  justify-content: center;
  margin-top: 2rem;
  z-index: 500;
}

@media (max-width: 800px) {
  .switcherBtn__label {
    display: none;
  }
}
.district-select {
  background-color: rgba(225, 225, 225, 0.6);
  padding: 10px 10px !important;
  border-radius: 10px;
}

.district-select:focus {
  background-color: #e1e1e1;
}

.dashboard__menuitem {
  display: inline-block;
  padding: 0.5rem 1rem;
  transition: 0.15s;
  color: #939393;
  font-size: 14px;
}

.dashboard__menuitem:hover {
  border-bottom: 2px solid #ddd;
  color: #444;
}

.dashboard__menuitem.active {
  color: #444;
  border-bottom: 2px solid #ddd;
}

.infoBlock {
  display: flex;
  width: 220px;
  align-items: center;
  margin-left: 15px;
  padding-right: 15px;
  margin: 2rem 10px;
}

.infoBlock__iconContainer {
  display: flex;
  height: 45px;
  width: 45px;
  justify-content: center;
  align-items: center;
  margin-right: 10px;
  background-color: #fff2e9;
  border-radius: 10px;
  color: orange;
}

.infoBlock__details h6 {
  color: #92959e;
  font-size: 13px;
}
.infoBlock__details span {
  color: #15192c;
  font-size: 13px;
}

.seprator {
  border-right: 1px solid #ececee;
}

.dataSummaryItem {
  display: flex;
  justify-content: space-between;
  font-size: 11px;
  margin: 6px 8px;
}

.dataSummaryItem__total {
  border-top: 1px solid black;
  font-weight: bolder;
  padding-top: 10px;
}

.dashboard__sidebar__item {
  display: flex;
  gap: 15px;
  align-items: center;
  font-size: 12px;
  color: #80828a;
  text-align: left !important;
  padding: 0.8rem 1rem;
  width: 13rem;
  transition: 0.15s;
  cursor: pointer;
  text-decoration: none !important;
}
.dashboard__sidebar__item .icon_container {
  font-size: 15px;
}

.dashboard__sidebar__item:hover {
  color: #551fff;
  background-color: #f3f0ff;
}

.dashboard__sidebar__activeItem {
  color: #551fff;
  background-color: #f3f0ff;
}

.districtUser__basicInfo__container,
.dashboard__numbers {
  border-radius: 10px;
  width: 100%;
  background-color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
}

.dashboard__info__container {
  width: 680px;
}

.dashboard__chartsContainer {
  flex-grow: 1;
}

.charts__container {
  justify-content: center;
  align-items: flex-start;
  display: flex;
  flex-wrap: wrap;
}

.chart__item,
.chart__item__print {
  padding-bottom: 1.5rem;
  margin-bottom: 1.5rem;
  width: 310px;
  margin-left: 5px;
  border-radius: 10px;
  background-color: #fff;
  page-break-inside: avoid !important;
}

.chart__item__print {
  margin-bottom: 0;
  padding-bottom: 0;
}

.dashboard__numbers {
  justify-content: center;
  width: 660px;
  height: 110px;
  gap: 15px;
}

.dashboard__number__container {
  width: 200px;
  height: 70px;
  border-radius: 10px;
  background-color: #ffebef;
  padding: 10px 20px;
}
.dashboard__number__container h6 {
  font-size: 13px;
}
.dashboard__number__container span {
  color: #15192c;
  font-size: 18px;
}

.dashboard__details__container {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  gap: 1rem;
  margin-top: 3rem;
  margin-bottom: 3rem;
  align-items: flex-start;
}

.dashboard__detailItem__header {
  font-size: 12px;
  padding-bottom: 10px;
  border-bottom: 1px solid black;
  color: #454545;
  margin: 5px;
  margin-bottom: 10px;
}

.dashboard__detailItem,
.dashboard__detailItem__print {
  width: 310px;
  border-radius: 10px;
  background-color: #fff;
  margin-bottom: 1rem;
  padding: 1rem;
}

.dashboard__detailItem__print {
  margin-bottom: 0;
  padding-top: 0;
  padding-bottom: 0.7rem;
}

.dashboard__menu__container {
  background-color: #fff;
  height: 90vh;
  display: flex;
  padding: 0 2rem;
  flex-direction: column;
  align-items: center;
}
.dashboard__menu__container img {
  width: 13rem;
  margin-top: 25px;
  margin-bottom: 15px;
}

.custom__separtor {
  margin-top: 1.5rem;
  margin-bottom: 1.5rem;
  width: 2rem;
  border-top: 5px dotted #aaa;
}

@media screen and (min-width: 1644px) {
  .chart__item,
  .chart__item__print {
    margin-right: 15px;
  }
}
@media print {
  .dashboard__details__container {
    margin-top: 1rem;
  }
  .dashboard__detailItem,
  .dashboard__detailItem__print {
    page-break-inside: avoid;
  }
  .dashboard__detailItem__print {
    page-break-inside: avoid;
  }
  .chart__item,
  .chart__item__print {
    page-break-inside: avoid;
  }
}
.map__overlay__layer {
  position: absolute;
  top: 0;
  left: 0;
  height: inherit;
  width: inherit;
}

.school-map__resizeable__container {
  width: -moz-fit-content;
  width: fit-content;
}

.mapmodule__popup__container {
  background-color: white;
  border-radius: 5px;
  box-shadow: 0 0 5px #bebebe;
  padding: 1rem;
}

.side-tools__container {
  padding: 1rem;
  background-color: #f8f8f8;
  border-top: 2px solid #fff;
}

.side-tools__rect__items {
  display: flex;
  justify-content: space-between;
}

.side-tools__group__form {
  margin-top: 2rem;
}

.btn:disabled {
  opacity: 0.4;
  cursor: not-allowed;
}

.school-map__sidebar {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 0;
  height: 35px;
  background-color: white;
  border-bottom: 1px solid #f3f3f3;
  margin-bottom: 3px;
  border-right: 2px solid #f2f2f2;
  color: #656565;
  font-size: smaller;
  background-color: snow;
}
.school-map__sidebar .sidebar__schoolName {
  display: inline-flex;
  gap: 10px;
  align-items: center;
  font-size: 14px;
  margin-right: 5px;
}

.school-map__sidebar::-webkit-scrollbar-thumb {
  border-radius: 0;
  background-color: #ccc;
}

.school-map__playground {
  display: flex;
  width: 100%;
  height: 84vh;
  position: relative;
}

.school-map__playground__TheMap {
  flex: 8;
}

.school-map__playground__info {
  flex: 2;
  box-shadow: -7px 0px 5px -5px #f2f2f2;
  display: flex;
  justify-content: center;
}

.schoo-map__palayground-main {
  position: relative;
  border-radius: 5px;
  padding: 0 !important;
  margin: 0px !important;
}

.school-map__side-tools__shapes-list {
  display: flex;
  justify-content: space-between;
  padding: 1px 2px;
  padding-left: 8px;
  border: 1px solid transparent;
  border-radius: 3px;
}

.school-map__side-tools__shapes-list__clicked {
  font-weight: bold;
  background-color: #e2e2e2;
}

.school-map__resource-item {
  padding: 0.5rem;
  cursor: pointer;
  border-radius: 5px;
}

.school-map__resource-item:hover {
  background-color: #efefef;
}

.school-map__shape-rotate-slider-container {
  display: flex;
  margin-top: 15px;
  margin-bottom: 15px;
  margin-left: 0.6rem;
  margin-right: 0.7rem;
  justify-content: space-between;
  align-items: center;
}

.rangeslider,
.rangeslider-horizontal {
  margin: 0 !important;
}

.rangeslider__fill {
  background-color: #4fc9da !important;
}

.shape-rotate-badge__container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 20px;
  height: 20px;
  background-color: tomato;
  color: white;
  border-radius: 100%;
}

.school-map__side-tools__group-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 4px 7px;
  font-size: 11px;
}
.school-map__side-tools__group-header .sideTools__groupName {
  font-size: 14px;
  font-weight: 500;
}

.layer-form__input {
  width: 70%;
  padding: 0.375rem 0.75rem;
  font-size: 12px;
  font-weight: 400;
  color: #212529;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 5px;
}

.school-map__playground__icon-container {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 10px;
  width: 10px;
  font-size: 5px;
  border-radius: 100%;
}

.node rect {
  stroke: black;
  stroke-width: 1px;
}

.node text {
  font-size: 20px;
  color: white;
  background-color: dodgerblue;
}

.node {
  cursor: grab;
}

.schoolMap__sideTools__addShape__popupMenu__item {
  font-size: 13px;
  padding: 7px;
  cursor: pointer;
}

.schoolMap__sideTools__addShape__popupMenu__item:hover {
  background-color: #ccc;
}

.schoolMap__sideTools__addShape__popupMenu__item:last-of-type {
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
}

.schoolMap__sideTools__addShape__popupMenu__item:first-of-type {
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
}

.schoolMap__sidebarTools__btn {
  font-size: 11px;
  padding: 3px 7px;
  color: #3c3c43;
  border: 1px solid transparent;
  border-radius: 3px;
}

.schoolMap__sidebarHeader__addBtn {
  font-size: 11px;
  padding: 3px 7px;
  color: white;
  background-color: #03c988;
  border: 1px solid transparent;
  border-radius: 3px;
}

button.active-btn {
  background-color: #008ecc;
  color: white;
}

.school-map__control-btn {
  cursor: pointer;
  display: inline-flex;
  height: 35px;
  width: 35px;
  justify-content: center;
  align-items: center;
  border-radius: 100%;
  background-color: snow;
  color: #a5a5a5;
  border: 1px solid snow;
  font-size: 13px;
  transition: 0.35s;
}

.school-map__control-btn:hover {
  color: #4fc9da;
  border: 1px solid #4fc9da;
  background-color: transparent;
}

.school-map__zoom-controls {
  margin-top: 10px;
  margin-bottom: 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.school-map-svg__container {
  display: flex;
  height: 70vh;
  width: 70vw;
  margin-top: 1.5rem;
  justify-content: center;
}

.school-map-svg__container-1 {
  height: 84vh;
  padding: 1px;
}

.cls-7:hover {
  stroke: yellow;
  stroke-width: 1px;
  cursor: pointer;
}

.cls-7-active {
  stroke: yellow !important;
  stroke-width: 1px !important;
  cursor: pointer !important;
}

.cls-6:hover {
  stroke: yellow;
  stroke-width: 4px;
  cursor: pointer;
}
.st6:hover {
  stroke: yellow;
  stroke-width: 4px;
  cursor: pointer;
}
.st7:hover {
  stroke: yellow;
  stroke-width: 4px;
  cursor: pointer;
}

.st5:hover {
  stroke: yellow;
  stroke-width: 4px;
  cursor: pointer;
}
.st4:hover {
  stroke: yellow;
  stroke-width: 4px;
  cursor: pointer;
}

.cls-1:hover {
  stroke: yellow;
  stroke-width: 4px;
  cursor: pointer;
}

.cls-7:hover {
  stroke: yellow;
  stroke-width: 4px;
  cursor: pointer;
}

.cls-n-active {
  stroke: yellow !important;
  stroke-width: 4px !important;
  cursor: pointer !important;
}

.cls-3-active {
  stroke: yellow !important;
  stroke-width: 4px !important;
  cursor: pointer !important;
}

.cls-6-active {
  stroke: yellow !important;
  stroke-width: 2px !important;
  cursor: pointer !important;
}

.svg-map__info-block {
  margin: 5px;
  color: #333;
  width: 95%;
}
.svg-map__info-block img {
  width: 100%;
  -o-object-fit: cover;
  object-fit: cover;
  border-radius: 5px;
}

.info-block__description {
  background-color: rgba(255, 255, 255, 0.7);
}

.SVGMapInfo__infoPiece__container {
  border: 1px solid #f2f2f2;
  border-radius: 2px;
  font-size: 10px;
  text-align: justify;
  margin-bottom: 5px;
  display: flex;
  align-items: center;
  height: 30px;
}

.SVGMapInfo__label {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 5px;
  padding-right: 0;
  background-color: #f2f2f2;
  width: 40%;
  border-radius: 2px;
  margin-right: 7px;
  font-weight: bold;
  height: 100%;
  text-align: left;
}

.schoolMap__printBtn__container {
  position: absolute;
  top: 10px;
  right: 0;
}

.schoolMap__printBtn {
  width: 40px;
  height: 40px;
  background-color: #f3f3f3;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  margin-right: 10px;
  cursor: pointer;
}

@media print {
  .school-map-svg__container-1 {
    margin: 1rem;
  }
}
.school-map__entity-select-container {
  width: 15rem;
  margin: 1rem auto 0;
}

.buildingReport__container {
  display: none;
  visibility: hidden;
}

@media print {
  .buildingReport__container {
    display: flex;
    visibility: visible;
  }
}
.buildingsystemtable__inputs {
  width: 100%;
  border: 0;
}

.buildingSystemsTable td {
  padding: 0;
  margin: 0;
  border: 1px solid #afafaf;
}

.buildingSystemsTable input:focus {
  outline: 1px solid dodgerblue;
}

.buildingSystemsTable tr:nth-child(even) input {
  background-color: #f2f2f2;
}

@media screen and (max-width: 991px) {
  div.cockpit {
    width: 100vw;
    height: 600px;
    display: none !important;
  }
  .login-form-container {
    height: auto;
  }
  .logo-container {
    display: none;
  }
  .modal-box {
    max-width: 80%;
    max-height: 560px;
  }
  div.modal-content {
    width: 70% !important;
  }
}
@media screen and (min-width: 1439px) {
  div.cockpit .feature-photo-container {
    width: 50% !important;
    margin-bottom: 6rem !important;
  }
  div.cockpit .slogan {
    width: 90%;
    font-size: 3.2rem;
  }
  .school-map-svg__container-1 {
    height: 90vh !important;
  }
  .school-map__playground {
    height: 90vh;
  }
}
@media screen and (min-width: 991px) {
  div.cockpit .feature-photo-container {
    width: 45%;
  }
  .signinForm__topLogo {
    display: none;
  }
}
@media screen and (max-width: 991px) {
  .form {
    width: 100vw;
  }
}
@media screen and (max-width: 576px) {
  .form .form-control {
    width: 21rem;
  }
  .input-control-small {
    width: 17rem;
  }
  .icon-input-small {
    width: 21rem;
  }
  .input-control,
  .input-control-small {
    width: 18rem;
  }
  button.btn-custom {
    width: 21rem;
  }
  .slogan {
    width: 65%;
    font-size: 2rem;
  }
  .modal-box {
    max-width: 90%;
  }
  div.modal-content {
    width: 90% !important;
    padding: 2.5rem 0.7rem !important;
  }
}
@media screen and (min-width: 576px) {
  .cockpit .feature-photo-container {
    width: 50%;
  }
  .cockpit .slogan {
    width: 55%;
    font-weight: 200;
  }
}
@media screen and (min-width: 720px) {
  .cockpit .feature-photo-container {
    width: 35%;
  }
}
@media screen and (max-width: 420px) {
  .modal-box {
    max-width: 99%;
  }
} /*# sourceMappingURL=style.css.map */

/* Change the white to any color */
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0 30px white inset !important;
}

select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus,
select:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0 30px white inset !important;
}

.ck.ck-toolbar {
  background-color: #334155 !important;
}

.ck .ck-button__label {
  color: #fff !important;
}

.modalContent {
  max-height: 80vh; /* or any other value that suits your design */
  overflow-y: auto;
}
