.infoBlock {
  display: flex;
  width: 220px;
  align-items: center;
  margin-left: 15px;
  padding-right: 15px;
  margin: 2rem 10px;
}

.infoBlock__iconContainer {
  display: flex;
  height: 45px;
  width: 45px;
  justify-content: center;
  align-items: center;
  margin-right: 10px;
  background-color: #fff2e9;
  border-radius: 10px;
  color: orange;
}

.infoBlock__details {
  h6 {
    color: #92959e;
    font-size: 13px;
  }

  span {
    color: #15192c;
    font-size: 13px;
  }
}

.seprator {
  border-right: 1px solid #ececee;
}
