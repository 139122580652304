.sidebar-container {
  width: 100%;
  height: 100%;
}

.sidebar-wrapper {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

.sidebar-backdrop {
  position: fixed;
  height: 100vh;
  width: 100vw;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 100;
  background-color: rgba($color: #000000, $alpha: 0.5);
}

.sidebar-box {
  position: fixed;
  margin: auto;
  top: 0;
  right: 0;
  height: 100%;
  overflow-y: auto;
  background-color: white;
  box-shadow: 0 0 10px rgba(white, 0.25);
  z-index: 10000;
  // padding: 2.5rem;
  border-radius: 5px;
}

.image-grid img {
  border-radius: 5px;
}

.image-grid {
  --gap: 16px;
  --num-cols: 2;
  --row-height: 125px;

  box-sizing: border-box;
  padding: var(--gap);

  display: grid;
  grid-template-columns: repeat(var(--num-cols), 1fr);
  grid-auto-rows: var(--row-height);
  gap: var(--gap);
}

.image-grid > img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.image-grid-col-2 {
  grid-column: span 2;
}

.image-grid-row-2 {
  grid-row: span 2;
}
