.cropper-container {
  height: 90vh;
  padding: 1.5rem;
  display: flex;

  flex-direction: column;
  justify-content: space-between;

  .cropper {
    display: flex;
    gap: 1.5rem;
    justify-content: center;
    height: 60vh;
    img {
      object-fit: cover;
    }
  }
}

.crop-profile-btn {
  border: 1px solid transparent !important;
  border-radius: 5px;
  outline: 0;
  padding: 0.7rem 1rem;
}

.crop-profile-upload-btn {
  border: 1px solid rgba($color: $primary, $alpha: 0.8) !important;
  background-color: rgba($color: $primary, $alpha: 0.9);
  color: white;
}
.crop-profile-upload-btn:hover {
  background-color: $primary;
}

.crop-profile-btn-clear {
  border: 1px solid #ddd !important;
  background-color: white !important;
}

.crop-profile-btn:disabled {
  opacity: 0.8;
  cursor: not-allowed;
}

.profilepic__input {
  position: absolute;
  left: -999px;
  width: 1px;
  height: 1px;
  opacity: 0;
  visibility: hidden;
}

.profilepic__label {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  padding: 10px 15px;
  background-color: $primary;
  border-radius: 30px;
  cursor: pointer;
  transition: 0.3s;
}

.profilepic__text {
  color: white;
}

.profilepic__icon {
  margin-right: 15px;
  color: white;
}

.profilepic__label:hover {
  transform: translateY(-5px);
  box-shadow: 0 4px 10px rgba($color: black, $alpha: 0.1);
}
