$table_radius: 5px;

.table-container {
  border: 1px solid #eee;
  border-radius: $table_radius;
}

table {
  // border-collapse: separate !important;
  border-radius: $table_radius;
  width: 100%;
  color: #555;
  font-size: 12px;
}

tr:first-child th:first-child {
  border-top-left-radius: $table_radius;
}
tr:first-child th:last-child {
  border-top-right-radius: $table_radius;
}
tr:last-child td:first-child {
  border-bottom-left-radius: $table_radius;
}
tr:last-child td:last-child {
  border-bottom-right-radius: $table_radius;
}

table td,
table th {
  // border: 1px solid #ddd;
  padding: 8px;
}

table tr:nth-child(even) {
  background-color: #f2f2f2;
}

table tr:hover {
  background-color: #ddd;
}

table th,
tfoot td {
  padding-top: 12px;
  padding-bottom: 12px;
  background-color: snow;
}
