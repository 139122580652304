.layout__switcher {
  display: inline-block;
  padding: 0.3rem 0.5rem;
  background-color: #f8f8f8;
  //   color: $primary;
  color: #888;
  font-size: 13px;
  border-radius: 5px;
  transition: 0.3s;
}

.layout__switcher:hover {
  background-color: #f1f1f1;
}

.option-disabled {
  opacity: 0.4;
  cursor: not-allowed !important;
}

.option-active {
  color: #c06c84;
  background-color: rgba($color: #c06c84, $alpha: 0.1);
}

.option-active:hover {
  background-color: rgba($color: #c06c84, $alpha: 0.15);
}
