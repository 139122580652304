.form {
  display: flex;
  flex-direction: column;
  background-color: snow;
  align-items: center;
  justify-content: center;
  width: 35vw;
  color: $secondary;
  height: 100vh;
  box-shadow: rgba(0, 0, 0, 0.05) 0px 1px 2px 0px;

  .form-label {
    font-weight: 600;
  }
}

.input-control {
  background-color: rgba($input-background, 0.2);
  border-radius: 0.8rem;
  display: block;
  width: 17rem;
  padding: 0.65rem 1rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
  background-clip: padding-box;
  border-width: 0;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  transition: border-color 0.15s ease-in-out, background-color 0.15s ease-in-out;
}

.input-control-small {
  @extend .input-control;

  width: 8.5rem;
}

.icon-input {
  background-color: rgba($input-background, 0.6);
  border: 1px solid rgba($input-background, 0.6);
  border-radius: 10px;
  width: 21rem;
  transition: background-color 0.15s ease-in-out;
}

.icon-input-small {
  @extend .icon-input;
  width: 10rem;
}

.input-control:focus,
.icon-input:focus-within,
.icon-input-small:focus-within {
  outline: 0;
  border: 1 solid $input-background;
  background-color: $input-background;
}

button.btn-custom {
  padding: 0.65rem 1rem;
  border-radius: 10px;
  font-weight: 500;
  font-size: 0.8rem;
  width: 21rem;
}

button.btn-custom-primary {
  color: #fff;
  background-color: $primary;
  border-color: $primary;
}

button.btn-custom-primary:hover {
  color: #fff;
  background-color: $primary;
  border-color: $primary;
}

.text-bold {
  font-weight: 500;
}

.icon {
  height: 20px;
}

.sign-in-out-header {
  width: 400px;
  text-align: center;
}

.normalize-text {
  // font-weight: 500;
  font-size: 0.8rem;
}

.error-text {
  font-size: 0.6rem;
  padding-top: 0.15rem !important;
  position: absolute;
}

div#terms-conditions {
  width: 21rem;
  word-wrap: break-word;
  text-align: justify;
  font-size: 0.6rem;
}
