.map__overlay__layer {
  position: absolute;
  top: 0;
  left: 0;
  height: inherit;
  width: inherit;
  // border: 1px solid red;
}

.school-map__resizeable__container {
  width: fit-content;
}

// *******************************************************
.mapmodule__popup__container {
  background-color: white;
  border-radius: 5px;
  box-shadow: 0 0 5px #bebebe;
  padding: 1rem;
}

// *********************************************************

.side-tools__container {
  // border: 1px solid #888;
  padding: 1rem;
  background-color: #f8f8f8;
  border-top: 2px solid #fff;
}

.side-tools__rect__items {
  display: flex;
  justify-content: space-between;
}

.side-tools__group__form {
  margin-top: 2rem;
}

.btn:disabled {
  opacity: 0.4;
  cursor: not-allowed;
}

// ******************************************* New Styles
// .school-map__container {
// }

.school-map__sidebar {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 0;
  // width: 270px;
  // background-color: snow;
  height: 35px;
  background-color: white;
  // position: fixed;
  // height: 90%;
  border-bottom: 1px solid #f3f3f3;
  margin-bottom: 3px;

  border-right: 2px solid #f2f2f2;
  color: #656565;
  font-size: smaller;
  // overflow-y: auto;
  background-color: snow;

  .sidebar__schoolName {
    display: inline-flex;
    // justify-content: space-between;
    gap: 10px;
    align-items: center;
    font-size: 14px;
    // text-align: center;
    // font-weight: bold;
    // background-color: #6e7582;
    margin-right: 5px;
    // padding: 5px;
    // color: white;
  }
}

.school-map__sidebar::-webkit-scrollbar-thumb {
  border-radius: 0;
  background-color: #ccc;
}

.school-map__playground {
  display: flex;
  width: 100%;
  height: 84vh;
  position: relative;
}

.school-map__playground__TheMap {
  flex: 8;
}

.school-map__playground__info {
  flex: 2;
  box-shadow: -7px 0px 5px -5px #f2f2f2;
  display: flex;
  justify-content: center;
  width: 300px;
}

.schoo-map__palayground-main {
  position: relative;
  border-radius: 5px;
  padding: 0 !important;
  margin: 0px !important;
  // display: flex;
  // height: 450px;
  // width: 60vw;
}

.school-map__side-tools__shapes-list {
  display: flex;
  justify-content: space-between;
  // margin: 1px;
  padding: 1px 2px;
  padding-left: 8px;
  border: 1px solid transparent;
  border-radius: 3px;
}

.school-map__side-tools__shapes-list__clicked {
  font-weight: bold;
  background-color: #e2e2e2;
}

.school-map__resource-item {
  padding: 0.5rem;
  cursor: pointer;
  border-radius: 5px;
}

.school-map__resource-item:hover {
  background-color: #efefef;
}

.school-map__shape-rotate-slider-container {
  // border: 1px solid dodgerblue;
  display: flex;
  margin-top: 15px;
  margin-bottom: 15px;
  margin-left: 0.6rem;
  margin-right: 0.7rem;
  justify-content: space-between;
  align-items: center;
}

.rangeslider,
.rangeslider-horizontal {
  margin: 0 !important;
}

.rangeslider__fill {
  background-color: $primary !important;
}

.shape-rotate-badge__container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 20px;
  height: 20px;
  background-color: tomato;
  color: white;
  border-radius: 100%;
}

.school-map__side-tools__group-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  // background-color: #6e7582;
  // color: white;
  padding: 4px 7px;
  // margin-bottom: 7px;
  // margin-top: 1.5rem;
  font-size: 11px;

  .sideTools__groupName {
    font-size: 14px;
    font-weight: 500;
  }
}

.layer-form__input {
  // display: inline-block;
  width: 70%;
  padding: 0.375rem 0.75rem;
  font-size: 12px;
  font-weight: 400;
  color: #212529;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 5px;
}

.school-map__playground__icon-container {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 10px;
  width: 10px;
  font-size: 5px;
  border-radius: 100%;
}

// printed nodes of playground

.node rect {
  stroke: black;
  stroke-width: 1px;
}

.node text {
  font-size: 20px;
  color: white;
  background-color: dodgerblue;
}

.node {
  cursor: grab;
}

.schoolMap__sideTools__addShape__popupMenu__item {
  font-size: 13px;
  padding: 7px;
  cursor: pointer;
}

.schoolMap__sideTools__addShape__popupMenu__item:hover {
  background-color: #ccc;
}

.schoolMap__sideTools__addShape__popupMenu__item:last-of-type {
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
}

.schoolMap__sideTools__addShape__popupMenu__item:first-of-type {
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
}

.schoolMap__sidebarTools__btn {
  font-size: 11px;
  padding: 3px 7px;
  color: #3c3c43;
  // background-color: #6e7582;
  border: 1px solid transparent;
  border-radius: 3px;
}

.schoolMap__sidebarHeader__addBtn {
  font-size: 11px;
  padding: 3px 7px;
  color: white;
  background-color: #03c988;
  border: 1px solid transparent;
  border-radius: 3px;
}

button.active-btn {
  background-color: #008ecc;
  color: white;
}
