.custom-input {
  display: block;
  width: 100%;
  padding: 0.375rem 0.75rem;
  font-size: 12px;
  font-weight: 400;
  color: #212529;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 5px;
}

.custom-select {
  @extend .custom-input;
  padding: 0.375rem 2.25rem 0.375rem 0.75rem;
}

.custom-input:focus {
  outline: 0;
}

.custom-input:disabled {
  background-color: #e9ecef;
}

.input__label {
  font-size: "10px";
  margin-left: 1px;
  color: #555;
}

.custom-input-1 {
  @extend .custom-input;
  width: 80%;
  display: inline-block;
}
