.profile-avatar-container {
  cursor: pointer;
  // background-color: rgba($color: $primary, $alpha: 0.7);
  // background-color: white;
  border-radius: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 40px;
  width: 40px;
  padding: 0 !important;
  position: relative;
  // padding: 0.4rem 0.8rem;
}

.profile-avatar {
  display: inline-block;
  // vertical-align: middle;
  height: 33px;
  width: 33px;
  border-radius: inherit;
  background-color: #999;
  margin: 0;
  object-fit: contain;
}

.navbar__username {
  display: inline-block;
  margin-left: 0.5rem;
  font-weight: 1;
  font-size: 0.8rem;
  text-decoration: none;
  color: #555;
}

.custom-navbar {
  height: 60px;
  background-color: snow;
  border-bottom: 1px solid #f3f3f3;
  padding-left: 0 !important;
}

.custom-nav {
  // border: 1px solid red;
  align-items: center;
  height: 60px;
}

.custom-nav-item {
  display: inline-flex;
  align-items: center;
  padding: 0 20px;
  height: 60px;
  vertical-align: middle;
  font-size: 14px;
  color: #666;
  text-decoration: none;
  transition: 0.15s;
  font-family: "Rubik";
}

.custom-nav-item:hover {
  background-color: #eee;
  color: inherit;
}

.custom-nav-item-active {
  background-color: #eee;
  color: #000;
}

.navbar__avatar__popup {
  background-color: white;
  border-radius: 5px;
  border: 1px solid #f3f3f3;
  padding: 0;
  width: 150px;
  margin-right: 10px;
  position: absolute;
  left: -95px;
}

.navbar__avatar__popup__menu-item {
  padding: 5px 10px;
  color: #666;
  font-size: 14px;
  cursor: pointer;

  .navbar__avatar__popup__menu__icon {
    @extend .layout__switcher;
  }
}
.navbar__avatar__popup__menu-item:hover {
  .navbar__avatar__popup__menu__icon {
    background-color: #eee;
  }
}

.navbar__avatar__popup__menu-item:first-of-type {
  border-top-left-radius: inherit;
  border-top-right-radius: inherit;
}

.navbar__avatar__popup__menu-item:last-of-type {
  border-bottom-left-radius: inherit;
  border-bottom-right-radius: inherit;
}

.navbar__avatar__popup__menu-item a {
  text-decoration: none;
  color: inherit;
  border: 0;
}

.navbar__avatar__popup__menu-item a:hover {
  color: #666;
}

.navbar__avatar__popup__menu-item a:focus {
  outline: 0;
  border: 0;
}

.brand-logo {
  cursor: pointer;
  color: #9a9a9a;
  font-size: 25px;
}
