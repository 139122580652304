.custom-dropdown {
  cursor: pointer;
  width: 13rem;
  font-size: 12px;
  color: #555;
  border-radius: 30px;
  border: 1px solid #f3f3f3;
  // margin-left: 1.3rem;
  transition: all 0.15s linear;
  position: relative;
  background-color: #fff;
}

.custom-dropdown-margin {
  margin-left: 1.3rem;
}

.custom-dropdown-disabled {
  opacity: 0.7;
  cursor: not-allowed;
}

.custom-dropdown .custom-dropdown-btn {
  white-space: nowrap;
  padding: 5px 10px;
  background-color: #fff;
  box-shadow: 3px 3px 10px 6px rgba($color: #fff, $alpha: 0.06);
  color: #555;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-radius: 30px;
  text-overflow: ellipsis;
  overflow-x: hidden;
}

.custom-dropdown .custom-dropdown-content {
  position: absolute;
  width: 13rem;
  top: 1.85rem;
  z-index: 100000000;
  // padding: 15px;
  background: #fff;
  box-shadow: 3px 3px 10px 6px rgba($color: #fff, $alpha: 0.06);
  color: #555;
  font-size: 12px;
  border-radius: 5px;
  border: 1px solid #e1e1e1;
}

.custom-dropdown-item {
  // border: 1px solid red;
  border: 0;
  outline: 0;
  display: flex;
  justify-content: space-between;
  padding: 5px 10px;
  cursor: pointer;
}

.custom-dropdown-item:last-of-type {
  border-bottom-left-radius: inherit;
  border-bottom-right-radius: inherit;
}

.custom-dropdown-item:hover {
  background-color: $input-background;
}

.custom-dropdown-search-box {
  box-sizing: border-box;
  display: inline-block;
  width: 92%;
  margin: 0.5rem 4%;
  padding: 0.5rem;
  border: 1px solid #ddd;
  border-radius: 5px;
}

.custom-dropdown-search-box:focus {
  outline: 0;
  border: 1px solid $primary;
}

.custom-dropdown-btn-1 {
  @extend .custom-dropdown-btn;
  border-radius: 5px;
}

.custom-dropdown-1 {
  @extend .custom-dropdown;
  display: inline-block;
  border-radius: 5px;
  width: fit-content;
}
