.dashboard__menuitem {
  display: inline-block;
  padding: 0.5rem 1rem;
  transition: 0.15s;
  color: #939393;
  font-size: 14px;
}

.dashboard__menuitem:hover {
  border-bottom: 2px solid #ddd;
  color: #444;
}

.dashboard__menuitem.active {
  //   border-bottom: 0px;
  color: #444;
  border-bottom: 2px solid #ddd;
}
