.modal-wrapper {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

.modal-backdrop {
  position: fixed;
  height: 100vh;
  width: 100vw;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 100;
  background-color: rgba($color: #000000, $alpha: 0.2);
}

.modal-box {
  position: fixed;
  margin: auto;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  // transform: translate(-50%, -50%);
  // max-height: 560px;
  // max-width: 70%;
  max-height: 490px;
  max-width: 1080px;
  overflow-y: auto;
  // background-color: white;
  // box-shadow: 0 0 10px rgba(white, 0.25);
  z-index: 10000;
  // padding: 2.5rem;
  border-radius: 5px;
  overflow: hidden;
}

.modal-box::-webkit-scrollbar {
  width: 10px;
}

.modal-box::-webkit-scrollbar-track {
  background-color: rgba(darkgrey, 0.3);
  border-radius: 5px;
  margin-right: 5px;
}

.modal-box::-webkit-scrollbar-thumb {
  border-radius: 5px;
  background-color: grey;
}

div.modal-content {
  margin: 0 auto;
  padding: 2.5rem;
  width: 60% !important;
  border-width: 0 !important;
  // overflow-y: auto;
  // border: 1px solid red;
  // outline: 5px solid red;
}

div.modal-content-1 {
  margin: 0 auto;
  padding: 2.5rem;
  width: 95% !important;
  border-width: 0 !important;
  // overflow-y: auto;
  // border: 1px solid red;
  // outline: 5px solid red;
}

div.modal-content-2 {
  margin: 0 auto;
  padding: 2.5rem;
  padding-top: 0;
  width: 75% !important;
  border-width: 0 !important;
  // overflow-y: auto;
  // border: 1px solid red;
  // outline: 5px solid red;
}

.modal-icon {
  display: inline-block;
  padding: 0.5rem;
  background-color: rgba($color: $primary, $alpha: 0.2);
  color: $primary;
  font-size: 1.3rem;
  border-radius: 5px;
}

.form-on-modal-container {
  max-height: 73vh;
  overflow-x: auto;
  margin-right: 3px;
  margin-top: 3px;
}
