.school-map__control-btn {
  cursor: pointer;
  display: inline-flex;
  height: 35px;
  width: 35px;
  justify-content: center;
  align-items: center;
  border-radius: 100%;
  background-color: snow;
  color: #a5a5a5;
  border: 1px solid snow;
  font-size: 13px;
  transition: 0.35s;
}

.school-map__control-btn:hover {
  color: $primary;
  border: 1px solid $primary;
  background-color: transparent;
}
